import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import {
  Button,
  Form,
  Pagination,
  Popconfirm,
  Radio,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { FaEdit } from "react-icons/fa";
import { fetchFundReq, fundReqAction } from "../../../ApiServices/Apifun";
import { dispatchtoast } from "../../../Utils";

const FundRequest = () => {
  const dispatch = useDispatch();
  const [status, setstatus] = useState("rejected");
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(() => {
    dispatch(setselectedTab("4"));

    dispatch(setHeading("Fund Request Report"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ReportColumn = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <p className="font-medium w-[150px]">
          {new Date(`${text}`).toDateString()}
        </p>
      ),
    },
    {
      title: "User Detail",
      dataIndex: "user_id",
      key: "user_id",
      render: (text) => {
        return (
          <>
            <p className=" font-medium  overflow-y-scroll">{text?.name??"-"}</p>
            <p className=" font-medium  overflow-y-scroll">{text?.email??"-"}</p>
            <p className=" font-medium  overflow-y-scroll">{text?.mobile??"-"}</p>

          </>
        );
      },
    },
    {
      title: "UTR Id",
      dataIndex: "utr_id",
      key: "utr_id",
      render: (text) => {
        return (
          <>
            <p className=" font-medium  overflow-y-scroll">{text??"-"}</p>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <p style={{ width: "80px" }} className=" font-medium">
          {text} Rs.
        </p>
      ),
    },

    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text) => {
        return (
          <>
            <img
            alt="proof"
              src={ text}
              className="w-20"
              crossOrigin="anonymous"
            />
          </>
        );
      },
    },

    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
      render: (text) => {
        return (
          <>
            <Tooltip title={text}>
              <p className="font-medium w-[150px]  overflow-y-scroll">
                {text ?? "-"}
              </p>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <>
            {text === "approved" ? (
              <Tag color="green">Approved</Tag>
            ) : text === "rejected" ? (
              <Tag color="red">Rejected</Tag>
            ) : (
              <Tag color="yellow">Under Process</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record) => {
        return (
          <>
            <Popconfirm
              placement="left"
              title="Approve Or Reject"
              className="popover"
              disabled={text?.status === "approved" || text?.status==="rejected"}

              description={
                <div className="mt-4">
                  {/* <div
                    className={`${status === "rejected" ? "block" : "hidden"}`}
                  >
                    <p className="font-semibold">Remark</p>
                    <Input
                      placeholder="Enter Remark"
                      onChange={(e) => setRemark(e.target.value)}
                      className="mt-2"
                    />
                  </div> */}
                  <Radio.Group
                    className="mt-2"
                    onChange={(e) => setstatus(e.target.value)}
                    value={status}
                  >
                    <Radio value={"rejected"}>Rejected</Radio>
                    <Radio value={"approved"}>Approved</Radio>
                  </Radio.Group>
                </div>
              }
              onConfirm={() => handleAction(record)}
              onCancel={() => {}}
              okText={"Ok "}
              okButtonProps={{ className: "bg-primary" }}
              cancelText="No"
            >
              <div className={`${
                  text.status === "approved" || text.status==="rejected"
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-primary cursor-pointer"
                }  flex justify-center items-center h-10 rounded-full`}>
                <FaEdit color="white" size={15} />
              </div>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  function handleAction(data) {
    const payload = {
      // remark: remark,
      status: status,
      fundRequestId: data._id,
    };
    fundReqAction(payload)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          getReport();
        }
      })
      .catch((err) => console.log(err));
  }
  const getReport = (val) => {
    setPageLoading(true);
    fetchFundReq(
      `?status=${val?.status ?? "under_process"}&page=${current}&limit=${pageSize}`
    )
      .then((res) => {
        if (res.status) {
          setTotalPages(res.total);
          setData(res.data.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoading(false));
  };
  useEffect(() => {
    getReport();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, pageSize]);
  return (
    <>
      <div className="">
        <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
          <div className="flex justify-end items-center">
            <p className="font-medium text-xs cursor-pointer bg-red-600 hover:bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2">
              Clear Filter
            </p>
          </div>
          <div className="mt-2">
            <Form onFinish={(val) => getReport(val)}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 place-items-center">
                <Form.Item name="status" className="w-full">
                  <Select
                    defaultValue={"under_process"}
                    className="w-full"
                    placeholder="Select Status"
                    options={[
                      { label: "under_process", value: "under_process" },
                      { label: "Rejected", value: "rejected" },
                      { label: "Aprroved", value: "approved" },
                    ]}
                  />
                </Form.Item>
                <Form.Item className="w-full">
                  <Button
                    htmlType="submit"
                    style={{ color: "white" }}
                    className="w-full border-none hover:border-none outline-none hover:outline-none bg-primary text-white"
                  >
                    Filter
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4">
          <Spin spinning={pageLoading}>
            <Table
              className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
              columns={ReportColumn}
              pagination={false}
              dataSource={data}
            />
            <div className="flex justify-end items-center mt-4">
          <Pagination
            defaultPageSize={pageSize}
            showQuickJumper={false}
            defaultCurrent={current}
            current={current}
            showSizeChanger={true}
            onShowSizeChange={(a, b) => setPageSize(b)}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default FundRequest;
