import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { GetUserBettings } from "../../../ApiServices/Apifun";
import { Pagination, Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const UserBettings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [user, setUSer] = useState([]);
  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    }
    dispatch(setselectedTab("6"));
    dispatch(setHeading("User Bettings"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUSer = () => {
    setLoading(true);
    GetUserBettings(
      `?userId=${location.state.id}&page=${current}&limit=${pageSize}`
    )
      .then((res) => {
        if (res.status) {
          setTotalPages(res.total);
          setUSer(
            res.data.map((i, index) => {
              return { ...i, sno: index + 1 };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getUSer();
  }, [current, pageSize]);
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Choose Option ID",
      dataIndex: "choose_option_id",
      key: "choose_option_id",
      render: (text) => (
        <p className="capitalize text-center">{text ?? "N/A"}</p>
      ),
    },
    {
      title: " Room Id",
      dataIndex: "room_id",
      key: "room_id",
      render: (text,record) => (
        <div>
          <p className="uppercase">{record?.room_id?.room_id ?? "N/A"}</p>
          <p className="uppercase">{record?.room_id?.room_type ?? "N/A"}</p>
        </div>
      ),
    },
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      render: (text,record) =>  <div>
      <p className="capitalize">Name: {record?.user_id?.name ?? "N/A"}</p>
      <p className="capitalize">Email: {record?.user_id?.email ?? "N/A"}</p>
      <p className="capitalize">Mobile: {record?.user_id?.mobile ?? "N/A"}</p>

    </div>,
    },
    {
      title: "Bet Amount",
      dataIndex: "bet_amount",
      key: "bet_amount",
      render: (text, record) => <p>{text} Rs.</p>,
    },
    {
      title: "Platform Service Amount",
      dataIndex: "platform_service_amt",
      key: "platform_service_amt",
      render: (text, record) => <p>{text} Rs.</p>,
    },
    {
      title: "Winning Amount",
      dataIndex: "winning_amt",
      key: "winning_amt",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    // {
    //   title: "Withdrawable?",
    //   dataIndex: "is_withdrawable",
    //   key: "is_withdrawable",
    //   render: (text, record) => <p className="text-center">{text}</p>,
    // },
  ];
  return (
    <>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={user}
        />
      </div>
      <div className="flex justify-end items-center mt-4">
        <Pagination
          defaultPageSize={pageSize}
          showQuickJumper={false}
          defaultCurrent={current}
          current={current}
          showSizeChanger={true}
          onShowSizeChange={(a, b) => setPageSize(b)}
          total={totalPages}
          onChange={(page, pageString) => setCurrent(page)}
        />
      </div>
    </>
  );
};

export default UserBettings;
