
import Comission from "../Screens/Main/Comission/Comission";
import Dashboard from "../Screens/Main/Dashboard/Dashboard";
import FundRequest from "../Screens/Main/FundRequest/FundRequest";
import CurrentRoomStats from "../Screens/Main/GameList/CurrentRoomStats";
import GameList from "../Screens/Main/GameList/GameList";
import RoomDetail from "../Screens/Main/GameList/RoomDetail";
import PAymentSetting from "../Screens/Main/PaymentSetting/PaymentSetting";
import ChangePassword from "../Screens/Main/Setting/ChangePassword";
import UserBettings from "../Screens/Main/UserList/UserBettings";
import UserList from "../Screens/Main/UserList/UserList";
import WalletTxn from "../Screens/Main/UserList/WalletTxn";
import WithdrawalRequest from "../Screens/Main/WithdrawalRequest/WithdrawalRequest";
import ProtectedAuth from "./PrivateAuth";


export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/game-list",
          element: <GameList />,
        },
        {
          path: "/user-list",
          element: <UserList />,
        },
        {
          path: "/payment-setting",
          element: <PAymentSetting />,
        },
        {
          path: "/fund-request",
          element: <FundRequest />,
        },
        {
          path: "/withdrawal-request",
          element: <WithdrawalRequest />,
        },
        {
          path: "/current-room-stats",
          element: <CurrentRoomStats />,
        },
        {
          path: "/room-detail",
          element: <RoomDetail />,
        },
        {
          path: "/WalletTxn",
          element: <WalletTxn />,
        },
        {
          path: "/user-bettings",
          element: <UserBettings />,
        },
        {
          path: "/comission",
          element: <Comission />,
        },
        {
          path: "/change-password",
          element: <ChangePassword />,
        },
      ],
    },
  ];
};
