import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { GetUserList, UpdateUser } from "../../../ApiServices/Apifun";
import { Pagination, Switch, Table } from "antd";
import { FaEye } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { dispatchtoast } from "../../../Utils";

const UserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);

  const [loading, setLoading] = useState(false);
  const [user, setUSer] = useState([]);
  useEffect(() => {
    dispatch(setselectedTab("6"));
    dispatch(setHeading("Users List"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUSer = () => {
    setLoading(true);
    GetUserList(`?page=${current}&limit=${pageSize}`)
      .then((res) => {
        if (res.status) {
          setTotalPages(res.total);
          setUSer(
            res.data.map((i, index) => {
              return { ...i, sno: index + 1 };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getUSer();
  }, [current, pageSize]);
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => <p className="capitalize">{text ?? "N/A"}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p className="capitalize">{text ?? "N/A"}</p>,
    },
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text ?? "N/A"}</p>,
    },
    {
      title: "verified",
      dataIndex: "isVerified",
      key: "isVerified",
      render: (text) => (
        <div className="flex justify-center items-center">
          {text ? (
            <p className="bg-green-700 px-8 text-white font-semibold text-center">
              Verified
            </p>
          ) : (
            <p className="bg-red-700 px-8 text-white font-semibold text-center">
              Not verified
            </p>
          )}
        </div>
      ),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text) => (
    //     <div className="flex justify-center items-center">
    //       {text ? (
    //         <p className="bg-green-700 px-8 text-white font-semibold text-center">
    //           Active
    //         </p>
    //       ) : (
    //         <p className="bg-red-700 px-8 text-white font-semibold text-center">
    //           InActive
    //         </p>
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Password",
    //   dataIndex: "password",
    //   key: "password",
    //   render: (text, record) => <p>{text}</p>,
    // },
    {
      title: "Referral Code",
      dataIndex: "referral_code",
      key: "referral_code",
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Wallet Balance",
      dataIndex: "wallet_balance",
      key: "wallet_balance",
      render: (text, record) => (
        <p className="text-center">{text ?? "-"} Rs.</p>
      ),
    },
    {
      title: "User Betting",
      dataIndex: "wallet_balance",
      key: "wallet_balance",
      render: (text, record) => (
        <div
          onClick={() =>
            navigate("/user-bettings", { state: { id: record._id } })
          }
          className="flex  justify-center  items-center"
        >
          <FaEye
            className="bg-primary rounded-full w-12 h-12 p-4"
            color="white"
          />
        </div>
      ),
    },
    {
      title: "User status",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <div
          className="flex  justify-center  items-center"
        >
          <Switch
            value={record.status}
            checkedChildren={<p>Active</p>}
            unCheckedChildren={<p className="text-black">Inactive</p>}
            onChange={(val) =>
              UpdateUser({ status: val, userId: record._id })
                .then((res) => {
                  if (res.status) {
                    getUSer();
                    dispatchtoast(res.message);
                  }
                })
                .catch((err) => console.log(err))
            }
            className=""
          />
        </div>
      ),
    },
    {
      title: "Wallet Txn",
      dataIndex: "wallet_balance",
      key: "wallet_balance",
      render: (text, record) => (
        <div
          onClick={() => navigate("/WalletTxn", { state: { id: record._id } })}
          className="flex  justify-center rounded-full items-center"
        >
          <FaEye
            className="bg-primary  rounded-full w-12 h-12 p-4"
            color="white"
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="mt-4">
        <Table
          className="history-table animate-fade-in max-h-[800px]  overflow-x-auto text-white rounded-lg"
          columns={columns}
          loading={loading}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: "cursor-pointer text-[12px]",
            };
          }}
          dataSource={user}
        />
        <div className="flex justify-end items-center mt-4">
          <Pagination
            defaultPageSize={pageSize}
            showQuickJumper={false}
            defaultCurrent={current}
            current={current}
            showSizeChanger={true}
            onShowSizeChange={(a, b) => setPageSize(b)}
            total={totalPages}
            onChange={(page, pageString) => setCurrent(page)}
          />
        </div>
      </div>
    </>
  );
};

export default UserList;
