export const Authendpoint = {
  Login: "admin/login",
  logout: "admin/log-out",
};
export const Globalendpoints = {
  dashboardCount:"admin/fetch-dashboard-count",
  createGame:"admin/create-game",
  getGameList:"admin/fetch-games",
  updateGame:"admin/update-games/",
  addBankDetail:"admin/add-bank-details",
  updateBankDetail:"admin/update-bank-details",
  fetchBankDetail:"admin/fetch-bank-details",
  getPaymentSetting:"admin/get-payment-setting",
  updatePaymentSetting:"admin/update-payment-setting",
  fetchFundReq:"admin/fetch-fund-request",
  fundReqAction:"admin/update-fund-request",
  fetchWithdrawalReq:"admin/fetch-withdrawal",
  updateWithdrawalReq:"admin/update-withdraw-request",
  currentRoomStats:"admin/fetch-current-room-stats",
  roomDetails:"admin/fetch-room-details",
  chooseWinner:"admin/choose-custom-winner",
  userList:"admin/fetch-user-list",
  updateUser:"admin/update-user-status",
  userBettings:"admin/fetch-user-bettings",
  walletTxn:"admin/fetch-user-wallet-tranx",
  colorOption:"admin/options",
  changePassword: "admin/change-password",

};
