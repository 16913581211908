import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeading, setselectedTab } from "../../../Store/B2bslice";
import { addBank, fetchBank, updateBank } from "../../../ApiServices/Apifun";
import { dispatchtoast, ifscPattern } from "../../../Utils";
import { IoCloudUploadOutline } from "react-icons/io5";

import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Spin,
  Table,
  Upload,
} from "antd";
import { MdDelete, MdEdit } from "react-icons/md";

const PAymentSetting = () => {
  const [form] = Form.useForm();
  const formref = useRef();
  const [status, setStatus] = useState([
    {
      isActive: false,
      isDefault: false,
    },
  ]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [registeredBanks, setRegisteredBanks] = useState([]);
  const [image, setImage] = useState([]);
  const [binaryImage, setBinaryImage] = useState(null);
  const [defaults, setDefault] = useState(false);
  const dispatch = useDispatch();
  const getAllRegistered = () => {
    setSpinner(true);
    fetchBank()
      .then((res) => {
        setRegisteredBanks(res.data);
        setStatus(
          res.data.map((i, index) => {
            return {
              isActive: i.is_active,
              isDefault: i.is_default,
            };
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setSpinner(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("3"));

    dispatch(setHeading("Payment Setting"));
    getAllRegistered();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAdd = (val) => {
    const formdata = new FormData();
    setBtnLoading(true);
    let data = { ...val };
    data["is_default"] = defaults;
    delete data["attachment"];
    formdata.append("image", binaryImage);
    Object.keys(data).map((key) => formdata.append(key, data[key]));
    addBank(formdata)
      .then((res) => {
        if (res.status) {
          getAllRegistered();
          dispatchtoast(res.message);
          form.resetFields();
          setBinaryImage([])
          setImage([])
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleCustomReq = async ({ file, onSuccess, onError }) => {
    try {
      setBinaryImage(file);
      const base64Data = await readFileAsBase64(file);
      const dataUrl = await readFileAsDataUrl(file);
      const newFile = {
        ...file,
        name: file.name,
        thumbUrl: dataUrl,
        base64: base64Data,
      };
      setImage([newFile]);
      //   setImageBase64(base64Data);
      onSuccess();
    } catch (error) {
      console.error(error);
      onError(error);
    }
  };
  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  // Helper function to read a file as Base64 data
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result; // Remove the data URI prefix
        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleDelete = (data) => {
    const payload = {
      is_deleted: true,
      bank_id: data._id,
    };
    updateBank(payload).then((res) => {
      if (res.status) {
        dispatchtoast(res.message);
        getAllRegistered();
      }
    });
  };
  console.log({ status });
  const handleEdit = (data, index) => {
    let payload = {
      bank_id: data._id,
      is_active: status[index].isActive,
      is_default: status[index].isDefault,
    };
    // if (status === "active") {
    //   payload["is_active"] = true;
    // } else if (status === "default") {
    //   payload["is_default"] = true;
    // }
    updateBank(payload).then((res) => {
      if (res.status) {
        dispatchtoast(res.message);
        getAllRegistered();
        // setIsActive(false);
        // setIsDefault(false);
      }
    });
  };
  const ReportColumn = [
    {
      title: "Bank Name",
      dataIndex: "bank",
      key: "bank",
      render: (text) => <p className="font-medium w-[100px]">{text??"-"}</p>,
    },
    {
      title: "Account Number",
      dataIndex: "ac_no",
      key: "ac_no",
      render: (text) => {
        return (
          <>
            <p className=" font-medium  overflow-y-scroll">{text??"-"}</p>
          </>
        );
      },
    },
    {
      title: "IFSC Code",
      dataIndex: "ifsc_code",
      key: "ifsc_code",
      render: (text) => (
        <p style={{ width: "100px" }} className=" font-medium">
          {text??"-"}
        </p>
      ),
    },
    {
      title: "Upi Id",
      dataIndex: "upi_id",
      key: "upi_id",
      render: (text) => (
        <p style={{ width: "100px" }} className=" font-medium">
          {text ?? "-"}
        </p>
      ),
    },
    {
      title: "Account Holder Name",
      dataIndex: "ac_holder_name",
      key: "ac_holder_name",
      render: (text) => {
        return (
          <>
            <p className="font-medium w-[100px]  overflow-y-scroll">
              {text ?? "-"}
            </p>
          </>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image_path",
      key: "image_path",
      render: (text) => {
        return (
          <>
          {text?
            <img
              onError={(err) => {
                err.target.src =
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png";
                err.target.alt = "no";
              }}
              src={text}
              className="w-12 h-12 rounded-full"
              crossOrigin="anonymous"
            />:<img
            
            src={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"}
            className="w-12 h-12 rounded-full"
            crossOrigin="anonymous"
          />}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record, index) => {
        // setIsActive(record.is_active);
        // setIsDefault(record.is_default);
        return (
          <>
            <div className="flex justify-around items-center">
              <Popconfirm
                placement="left"
                title="Set active or default."
                description={
                  <div>
                    <Checkbox
                      onChange={(e) => {
                        let data = [...status];
                        data[index] = {
                          ...data[index],
                          isDefault: e.target.checked,
                        };
                        setStatus(data);
                      }}
                      defaultChecked={status[index].isDefault}
                      //   checked={isDefault}
                    >
                      Default
                    </Checkbox>
                    <Checkbox
                      onChange={(e) => {
                        let data = [...status];
                        data[index] = {
                          ...data[index],
                          isActive: e.target.checked,
                        };
                        setStatus(data);
                      }}
                      defaultChecked={status[index].isActive}
                      //   checked={isActive}
                    >
                      Active
                    </Checkbox>
                  </div>
                }
                className="popover"
                onConfirm={() => handleEdit(record, index)}
                onCancel={() => {}}
                okText={"Ok "}
                okButtonProps={{ className: "bg-primary" }}
                cancelText="No"
              >
                <div className="bg-primary p-2 cursor-pointer">
                  <MdEdit color="white" size={15} />
                </div>
              </Popconfirm>
              <Popconfirm
                placement="left"
                title="Are you sure you want to delete?"
                className="popover"
                onConfirm={() => handleDelete(record)}
                onCancel={() => {}}
                okText={"Ok "}
                okButtonProps={{ className: "bg-primary" }}
                cancelText="No"
              >
                <div className="bg-primary p-2 cursor-pointer">
                  <MdDelete color="white" size={15} />
                </div>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];
  //   useEffect(() => {}, [isActive, isDefault]);
  return (
    <>
      <div className="p-4  ">
        <Form
          ref={formref}
          form={form}
          name="basic"
          onFinishFailed={(err) => console.log(3)}
          onFinish={(val) => handleAdd(val)}
        >
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-x-5">
            <Form.Item
              name={`bank`}
              label={"Bank Name"}
              labelCol={{ span: 24 }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Select Bank Name!",
              //   },
              // ]}
            >
              <Input
                // filterOption={filterOption}
                placeholder="Select Bank"
                className=" text-sm  h-9 w-full  focus:border-primary    "
              />
            </Form.Item>{" "}
            <Form.Item
              name={`ac_no`}
              label={"Account Number"}
              labelCol={{ span: 24 }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Enter Account Number!",
              //   },
              //   {
              //     whitespace: true,
              //     message: "Field cannot contain only blank spaces",
              //   },
              // ]}
            >
              <Input
                placeholder="Enter Your Account Number!"
                name="benificiary_account"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`attachment`}
              label={"Qr Code"}
              className="w-full"
              labelCol={{ span: 24 }}
              
            >
              <Upload
                maxCount={1}
                onRemove={() => {
                  setImage([]);
                }}
                listType="picture"
                accept=".jpg, .jpeg, .png"
                fileList={image}
                customRequest={handleCustomReq}
              >
                <Button
                  className="bg-primary text-white border-none font-medium"
                  icon={<IoCloudUploadOutline />}
                >
                  Click to Upload
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name={`ifsc_code`}
              label={"IFSC Code"}
              labelCol={{ span: 24 }}
              rules={[
                // {
                //   required: true,
                //   message: "Enter IFSC Code!",
                // },
                {
                  pattern: ifscPattern,
                  message: "Please enter a valid IFSC number",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <Input
                placeholder="Enter IFSC Code"
                name="ifsc_code"
                onInput={(e) => {
                  const value = e.target.value.toUpperCase();
                  e.target.value = value;
                }}
              />
            </Form.Item>{" "}
            <Form.Item
              name={`upi_id`}
              label={"Upi Id"}
              labelCol={{ span: 24 }}
              rules={[
                // {
                //   required: true,
                //   message: "Enter Upi Id!",
                // },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <Input placeholder="Enter Upi Id" name="upi_id" />
            </Form.Item>{" "}
            <Form.Item
              name={`ac_holder_name`}
              label={"Benificiary Name"}
              labelCol={{ span: 24 }}
              rules={[
                // {
                //   required: true,
                //   message: "Enter benificiary name!",
                // },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <Input
                placeholder="Enter benificiary name"
                name="benificiary_name"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`is_default`}
              label={<p>&nbsp;</p>}
              labelCol={{ span: 24 }}
            >
              <Checkbox onChange={(e) => setDefault(e.target.checked)}>
                Set Default
              </Checkbox>
            </Form.Item>{" "}
          </div>
          <div className="flex justify-start gap-4 items-center mt-2">
            <Form.Item>
              <Button
                loading={btnLoading}
                htmlType="submit"
                className="bg-primary outline-none border-none text-white"
              >
                Add
              </Button>
            </Form.Item>
            <Form.Item></Form.Item>
          </div>
        </Form>
      </div>
      <div>
        <div className="mt-1">
          <Spin spinning={spinner}>
            <Table
              className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
              columns={ReportColumn}
              pagination={false}
              dataSource={registeredBanks}
            />
            {/* <div className="mt-2 mb-8 flex justify-end items-center">
               <CommonPagination totalPages={totalPages} current={current} pageSize={pageSize} setCurrent={setCurrent} setPageSize={setPageSize}/>
              </div> */}
          </Spin>
        </div>
      </div>
    </>
  );
};

export default PAymentSetting;
